import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { SliceZone } from "@prismicio/react";
import Layout from "../components/common/Layout";
import Hero from "../components/home/Hero";
import HomeMenu from "../components/home/HomeMenu";
import components from "../components/slices/Index";
import Seo from "../components/meta/Seo";
// import StoresContext from "../context/StoresContext";

const HomePage = ({ data }) => {
  if (!data) return null;
  const { settings } = data;
  return (
    <>
      {/* <Seo page={...} /> */}
      <Layout settings={settings}>
        <div style={{ minHeight: "150vh" }}>
          <Hero data={data.home.data.hero_homepage[0]} />
          <HomeMenu
            settings={settings}
            homeMenuSection={data.home.data.home_menu_section}
          />
          <SliceZone slices={data.home.data.body} components={components} />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query homepageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    home: prismicHomepage(lang: { eq: $lang }, id: { eq: $id }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        hero_homepage {
          hero_title
          hero_description
          hero_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        home_menu_section {
          category_title
          category_description
          link_label
          link_to_category {
            url
            type
            target
            uid
            id
          }
          category_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        body {
          ... on PrismicHomepageDataBodyPromoSection {
            id
            slice_type
            slice_label
            primary {
              mobile_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              desktop_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to_products {
                url
                type
              }
            }
          }
          ... on PrismicHomepageDataBodyHomeAbout {
            id
            slice_type
            slice_label
            primary {
              link_label
              title
              link {
                url
              }
              description {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyTestimonialsSlider {
            id
            slice_type
            primary {
              title
            }
            items {
              testimonial_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(HomePage);
